import * as actionTypes from "../actions";

var initialState = {
    is_loading: false,
    stationData: [],
    error: false,
    count: 0,
    noData: false,
    next: null,
    previous: null,
    stationDetail: {},
    rfs_stage: {},
    healthData: {},
    rppHealth_loading: true,
    stage: true,
    salesData: [],
    is_mail_loading: false ,
    insightsData : [], 
    fuelConsumerData :[],
};

function getUnique(array, key) {
    if (typeof key !== 'function') {
        const property = key;
        key = function (item) {
            return item[property];
        };
    }
    return Array.from(array.reduce(function (map, item) {
        const k = key(item);
        if (!map.has(k)) map.set(k, item);
        return map;
    }, new Map()).values());
}

const getPartnerStationReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.IS_LOADING: {
            return {
                ...state,
                is_loading: true,
            };
        }
        case actionTypes.IS_RPP_STAGE_LOADING: {
            return {
                ...state,
                stage: true
            }
        }
        case actionTypes.GET_FUEL_STATION_REPORTS: {
            return {
                ...state,
                is_loading: false,
                error: false,
                stationData: getUnique(state?.stationData?.concat(action?.payload?.results), "id"),
                count: action.payload.count,
                next: action.payload.next,
                previous: action.payload.previous,
                is_mail_loading: false
            };
        }
        case actionTypes.MAIL_SALES_REPORT: {
            return {
                ...state,
                is_mail_loading: false,
                salesData: action?.payload,
                error: false
            }
        }
        case actionTypes.MAIL_LOADING: {
            return {
                ...state,
                is_mail_loading: true,
            }
        }
        case actionTypes.GET_FUEL_STATION_REPORTS_FAIL: {
            return {
                ...state,
                is_loading: false,
                is_mail_loading: false,
                error: true
            };
        }
        case actionTypes.GET_FUEL_STATION_DETAIL: {
            return {
                ...state,
                is_loading: false,
                stationDetail: action.payload,
                is_mail_loading: false,
            }
        }
        case actionTypes.GET_GRAPH_DETAIL: {
            return {
                ...state,
                is_loading: false,
                graphDetail: action.payload,
                is_mail_loading: false,
            }
        }
        case actionTypes.GET_GRAPH_DETAIL_FAIL: {
            return {
                ...state,
                is_loading: false,
                is_mail_loading: false,
            }
        }
        case actionTypes.GET_REFUELER_STAGE: {
            return {
                ...state,
                rfs_stage: action.payload,
                is_loading: false,
                stage: false,
                is_mail_loading: false,
            }
        }
        case actionTypes.CLEAR_RPP_DATA: {
            return {
                ...state,
                stationData: [],
                stationDetail: {},
                rfs_stage: {},
                healthData: {},
                salesData: [],
                error: false,
                noData: false,
            }
        }
        case actionTypes.CLEAR_ALERTS: {
            return {
                ...state,
                salesData: [],
                error: false,
                noData: false,
            }
        }
        case  actionTypes.RFS_HEALTH: {
            return {
                ...state,
                healthData: action.payload,
                rppHealth_loading: false
            }
        }
        case actionTypes.RFS_HEALTH_LOADING: {
            return {
                ...state,
                rppHealth_loading: true
            }
        }
        case actionTypes.GET_FUEL_STATION_REPORTS_NO_DATA: {
            return {
                ...state,
                error: false,
                is_loading: false,
                noData: true
            }
        }
        case actionTypes.INSIGHTS_LOADING: {
            return {
                ...state,
                is_loading: true,
            }
        }
        case actionTypes.INSIGHTS_DATA: {
            return {
                ...state,
                is_loading: false,
                insightsData: action?.payload,
                error: false
            }
        }
        case actionTypes.GET_INSIGHTS_NO_DATA: {
            return {
                ...state,
                error: false,
                is_loading: false,
                noData: true
            }
        }
        case actionTypes.GET_INSIGHTS_FAIL: {
            return {
                ...state,
                is_loading: false,
                error: true
            };
        }
        case actionTypes.FUEL_CONSUMERS_LOADING: {
            return {
                ...state,
                is_loading: true,
            }
        }
        case actionTypes.FUEL_CONSUMERS_DATA: {
            return {
                ...state,
                is_loading: false,
                fuelConsumerData: action?.payload,
                error: false
            }
        }
        case actionTypes.FUEL_CONSUMERS_NO_DATA: {
            return {
                ...state,
                error: false,
                is_loading: false,
                noData: true
            }
        }
        case actionTypes.GET_FUEL_CONSUMERS_FAIL: {
            return {
                ...state,
                is_loading: false,
                error: true
            };
        }
        default: {
            return {
                ...state,
            };
        }
    }
};


export default getPartnerStationReducer;